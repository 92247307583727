import * as React from "react"
import * as ProgressPrimitive from "@radix-ui/react-progress"

import { cn } from "@/lib/utils"

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      "relative h-2 w-full overflow-hidden rounded-full bg-primary/20",
      className
    )}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className="h-full w-full flex-1 bg-primary transition-all"
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

// Create a component that displays a progress bar that fills up over a target time period.

const TimedProgress = (props: { durationMs: number, progressStep?: number }) => {
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    const interval = setInterval(() => {
      setValue((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + (props.progressStep || 1);
      });
    }, props.durationMs / (100 / (props.progressStep || 1)));
  }, [props.durationMs, props.progressStep]);
  return <Progress value={value} />;
};

const MultiIndicatorProgress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & { indicators: { color: string, value: number, label?: string }[] }
>(({ className, indicators, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      "relative h-2 w-full overflow-hidden rounded-full bg-primary/20",
      className
    )}
    {...props}
  >
    {indicators.map((indicator, index) => (
      <ProgressPrimitive.Indicator
        key={index}
        className={`h-full transition-all bg-${indicator.color}-400`}
        style={{
          width: `${indicator.value}%`,
          position: 'absolute',
          left: `${indicators.slice(0, index).reduce((acc, curr) => acc + curr.value, 0)}%`
        }}
        title={indicator.label}
      />
    ))}
  </ProgressPrimitive.Root>
));
MultiIndicatorProgress.displayName = ProgressPrimitive.Root.displayName;


export { Progress, TimedProgress, MultiIndicatorProgress };
