import { Header } from './header';

export const Container: React.ComponentType<React.PropsWithChildren<{ headerChildren?: React.ReactNode }>> = (props) => {
  return (
    <div key='1' className='flex flex-col min-h-screen'>
      <Header className='fixed left-0 top-0 w-screen z-50'>{props.headerChildren}</Header>
      <main className='flex-1 overflow-y-auto px-4 pb-4 min-h-100 flex flex-col'>
        <Header className='mb-4 invisible'>{props.headerChildren}</Header>
        {props.children}
      </main>
    </div>
  );
}
