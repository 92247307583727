import { ErrorBoundary } from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../components/logo';
import { Button } from '../components/ui/button';

const GovernGPTErrorBoundary: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ErrorBoundary
      fallback={
        <div>
          <p>An error occurred and our team has received this alert.</p>
          <p>
            Contact Mamal at{' '}
            <a className='text-blue-600' href='tel:6468324660'>
              (646) 832-4660
            </a>{' '}
            or{' '}
            <a className='text-blue-600' href='mailto:mamal@governgpt.com'>
              mamal@governgpt.com
            </a>{' '}
            for immediate support.
          </p>
        </div>
      }
    >
      <div className='w-screen h-screen overflow-none flex flex-col justify-center items-center gap-4'>
        <div onClick={() => navigate('/')} className='cursor-pointer'>
          <Logo />
        </div>
        <p>An error occurred, and our team has received this alert.</p>
        <p>
          Contact Mamal at{' '}
          <a className='text-blue-600' href='tel:6468324660'>
            (646) 832-4660
          </a>{' '}
          or{' '}
          <a className='text-blue-600' href='mailto:mamal@governgpt.com'>
            mamal@governgpt.com
          </a>{' '}
          for immediate support.
        </p>
        <div className='flex gap-4'>
          <Button onClick={() => navigate('/')} variant='outline'>
            Go Home
          </Button>
          <Button onClick={() => window.location.reload()} variant='default'>
            Refresh Page
          </Button>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default GovernGPTErrorBoundary;
