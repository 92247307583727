import { atom, useSetAtom, useAtomValue } from 'jotai';

// Atom to hold the list of replacement texts
const replacementTextAtom = atom<string[]>([]);

// Action atom to push an item to the replacementText list
export const addReplacementTextAtom = atom(null, (get, set, newText: string) => {
  const currentList = get(replacementTextAtom);
  set(replacementTextAtom, [...currentList, newText]);
});

// Action atom to reset the replacementText list to zero length
export const resetReplacementTextAtom = atom(null, (_get, set) => {
  set(replacementTextAtom, []);
});

export function useEditorState() {
  const addReplacementText = useSetAtom(addReplacementTextAtom);
  const resetReplacementText = useSetAtom(resetReplacementTextAtom);
  const replacementText = useAtomValue(replacementTextAtom);

  return {
    addReplacementText,
    resetReplacementText,
    replacementText
  };
}
