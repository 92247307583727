// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useAuthInfo } from '@propelauth/react';
import { KeyboardEvent, useEffect, useState } from 'react';

export function CopyToken() {
  // Listen for a double press of CMD + ` within 500ms to copy a string to the clipboard
  const [copyTokenTimeout, setCopyTokenTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const [copyTokenCount, setCopyTokenCount] = useState(0);

  const { accessToken } = useAuthInfo();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === '\\') {
        setCopyTokenCount(copyTokenCount + 1);
        if (copyTokenCount === 0) {
          setCopyTokenTimeout(
            setTimeout(() => {
              setCopyTokenCount(0);
            }, 500)
          );
        }
        if (copyTokenCount === 1) {
          if (copyTokenTimeout !== null) clearTimeout(copyTokenTimeout);
          setCopyTokenCount(0);

          if (accessToken) {
            console.log(`copied token to clipboard: ${accessToken}`);
            navigator.clipboard.writeText(accessToken);
          }
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [copyTokenCount, copyTokenTimeout, accessToken]);

  return <></>;
}
