import { Button, ButtonLoading } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from '@radix-ui/react-icons';
import { Markdown } from '@/components/generic/markdown';

import '@/css/display.css';
import { captureEvent } from '@/lib/analytics';
import { useSearchState } from '../use-search-state';
import HighlightCopy from '@/components/generic/highlight-copy';
import { CopyCitation } from '../../generic/copy-citation';
import { useCallback } from 'react';
import { useDDQState } from '@/components/ddq/use-ddq-state';

export function SearchDisplay({
  inDDQContext = false
}: {
  inDDQContext?: boolean;
}) {
  const {
    mutateCurrentEditStateWithCopy,
    pairObj,
    updateQuestionData,
    setReplacementTextConditionally,
    addPairToSavesFromSearch,
    removePairFromSavesFromSearch
  } = useDDQState();

  const {
    selectedResult: result,
    pagination,
    setSelectedResult,
    isCurrentAnswerCopying,
    isSelectedResultInSavesFromSearch
  } = useSearchState();

  const onAnswerCopy = useCallback(
    async (copyPairId: string) => {
      if (!result || !pairObj) return;

      console.log('answer copy from search', copyPairId);

      mutateCurrentEditStateWithCopy
        .mutateAsync({ copyPairId, id: pairObj[0].id })
        .then((value) => {
          updateQuestionData(pairObj[0].id, { content: value.text || '' });
          setReplacementTextConditionally({
            id: pairObj[0].id,
            content: value.text
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result, pairObj]
  );

  return (
    <div className='flex max-h-full min-h-full flex-col'>
      <div className='flex items-center px-4 py-2'>
        <div className='flex items-center gap-2'>
          <h1 className='text-xl font-bold'>Selected Question</h1>
        </div>
        <div className='ml-auto flex items-center gap-2'>
          {result && (
            <span className='text-muted-foreground'>
              {typeof pagination.current !== 'undefined' &&
              pagination.current !== -1
                ? pagination.current + 1
                : '--'}{' '}
              of {pagination.length}
            </span>
          )}
          {!result && <Button className='invisible' />}
          {result && (
            <Button
              className='px-3'
              variant='outline'
              disabled={pagination.prev === undefined}
              onClick={() => {
                captureEvent('analysisClickQuestionArrow', {
                  doc_id: result.pair.ddq_id || '',
                  pair_id: result.pair.id || '',
                  direction: 'prev'
                });

                setSelectedResult(pagination.prev as string);
              }}
            >
              <ArrowLeftIcon />
            </Button>
          )}
          {result && (
            <Button
              className='px-3'
              variant='outline'
              disabled={pagination.next === undefined}
              onClick={() => {
                captureEvent('analysisClickQuestionArrow', {
                  doc_id: result.pair.ddq_id || '',
                  pair_id: result.pair.id || '',
                  direction: 'next'
                });

                setSelectedResult(pagination.next as string);
              }}
            >
              <ArrowRightIcon />
            </Button>
          )}
        </div>
      </div>
      <Separator />
      {result ? (
        <div className='flex flex-1 max-h-full min-h-full flex-col'>
          <div className='flex flex-[1_1_0] items-start p-4 overflow-y-scroll'>
            <div className='flex flex-col w-full items-start gap-4 text-sm'>
              <div className='flex w-full justify-between items-start'>
                <Markdown
                  className='markdown-els mt-4'
                  content={[result.pair.question].join('\n\n')}
                  ddqId={result.pair.ddq_id}
                />
                <div className='flex gap-2 mb-8'></div>
              </div>
              <div className='w-full flex justify-end gap-2 mb-8'>
                {inDDQContext &&
                  result.pair.ddq.approved_date &&
                  (isSelectedResultInSavesFromSearch ? (
                    <Button variant='outline' className='text-green-500 hover:text-green-600'
                      onClick={() => removePairFromSavesFromSearch(result)}
                    >
                      <CheckIcon className='h-6 w-6 mr-2' /> Shows in Generation
                    </Button>
                  ) : (
                    <Button variant='outline' onClick={() => addPairToSavesFromSearch(result)}>
                      Use with Generation
                    </Button>
                  ))}
                {inDDQContext &&
                  result.pair.ddq.approved_date &&
                  (isCurrentAnswerCopying ? (
                    <ButtonLoading>Copy to Editor</ButtonLoading>
                  ) : (
                    <Button onClick={() => onAnswerCopy(result.pair.id)}>
                      Copy to Editor
                    </Button>
                  ))}
              </div>
              {result.pair.ddq.approved_date && (
                <CopyCitation
                  friendlyName={result.pair.ddq.friendly_name}
                  approvedDate={result.pair.ddq.approved_date.slice(0, 10)}
                />
              )}
              <HighlightCopy
                copyTitle='Copy Answer'
                collapse={!result.pair.ddq.approved_date}
              >
                <Markdown
                  className='markdown-els'
                  content={[result.pair.answer, ...result.pair.footnotes].join(
                    '\n\n'
                  )}
                  ddqId={result.pair.ddq_id}
                />
              </HighlightCopy>
            </div>
          </div>
        </div>
      ) : (
        <div className='p-8 text-center text-muted-foreground'>
          No question selected
        </div>
      )}
    </div>
  );
}
