import { DDQPairWithMeta } from '@/types';
import { DDQPair } from '@/types';
import { SimilarPair, DDQ } from '@/types';
import { useAuthInfo } from '@propelauth/react';
import { useState } from 'react';

export type ResultType = {
  [key: string]: {
    processing: boolean;
    complete: boolean;
    response:
      | {
          pair: DDQPair;
          ddq: DDQ;
          generation: string;
          sources: DDQPairWithMeta[];
        }
      | undefined;
  };
};

interface ResolvablePromise extends Promise<void> {
  isResolved?: boolean;
}

export const useAutoComplete = () => {
  const { accessToken } = useAuthInfo();

  const [isProcessing, setAutoProcessing] = useState(false);

  const [results, setAutoResults] = useState<ResultType>({});

  const process = async ({ ddqPairs }: { ddqPairs: SimilarPair[] }) => {
    setAutoProcessing(true);
    setAutoResults({});

    const processPair = async (pair: SimilarPair) => {
      const pairId = pair[0].id;
      setAutoResults((prevResults) => ({
        ...prevResults,
        [pairId]: { processing: true, complete: false, response: undefined }
      }));     

      try {
        const contextPairs = pair[1]
          .filter((x) => x.is_relevant === 'high')
          .map((x) => x.pair.id);

        if (contextPairs.length === 0) {
          throw new Error('No context pairs, so this will register as requiring a response.')
        }

        const response = await fetch(
          `${import.meta.env.VITE_API_HOST}/pair/${pairId}/draft`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              context_pairs: contextPairs,
              instructions: ''
            })
          }
        );
        const data = await response.json();

        setAutoResults((prevResults) => ({
          ...prevResults,
          [pairId]: { processing: false, complete: true, response: data }
        }));
      } catch (error) {
        console.error('Error processing pair:', pairId, error);
        setAutoResults((prevResults) => ({
          ...prevResults,
          [pairId]: { processing: false, complete: true, response: undefined }
        }));
      }
    };

    const processPairsInParallel = async () => {
      const maxParallel = 5;
      let activePromises: ResolvablePromise[] = [];

      for (const pair of ddqPairs) {
        if (activePromises.length >= maxParallel) {
          await Promise.race(activePromises);
          activePromises = activePromises.filter((p) => !p.isResolved);
        }

        const promise = processPair(pair) as ResolvablePromise;
        promise.isResolved = false;
        promise.then(() => {
          promise.isResolved = true;
        });
        activePromises.push(promise);
      }

      await Promise.all(activePromises);
      setAutoProcessing(false);
    };

    await processPairsInParallel();
  };

  return {
    isProcessing,
    results,
    process
  };
};
