import { useState } from 'react';
import { SearchDialog } from './components/search-dialog';
import { useSearchState } from './use-search-state';
import { SearchInput, SearchButton } from './components/search-input';

interface SearchBarProps {
  onSearchEvent?: (query: string) => void;
  searchLabel?: string;
  inDDQContext?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearchEvent, searchLabel = 'Search the database...', inDDQContext = false }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { searchQuery, addSearchQuery } = useSearchState();

  const handleSearch = () => {
    if (searchQuery.length === 0) return;

    addSearchQuery(searchQuery);

    // Optionally emit a search event
    if (onSearchEvent) {
      onSearchEvent(searchQuery);
    }

    setIsDialogOpen(true);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  return (
    <div className='flex flex-1 items-center mx-20'>
      <SearchInput 
        onSearch={handleSearch} 
        onClickInput={handleOpenDialog} 
        placeholder={searchLabel} 
      />
      <SearchButton onSearch={handleSearch} onClickButton={handleOpenDialog} />
      <SearchDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        searchLabel={searchLabel}
        inDDQContext={inDDQContext}
      />
    </div>
  );
};

export default SearchBar;
