import { captureEvent, runTimerInMs } from '@/lib/analytics';
import { Category } from '@/types';
import { useAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';

export const useCategoryQuery = () => {
  const { accessToken } = useAuthInfo();

  const categoryQuery = useQuery<Category[]>({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    queryKey: ['ddqs', 'categories'],
    queryFn: async () => {
      const timer = runTimerInMs();

      captureEvent('loadDdqCategories', {});

      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_HOST}/categories/list`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        const data: Category[] = await response.json();

        const elapsed = timer();

        captureEvent('loadDdqCategoriesSuccess', {
          loadTime: elapsed
        });

        return data.sort((a, b) => ('' + a.name).localeCompare(b.name));
      } catch (error) {
        const elapsed = timer();

        captureEvent('loadDdqCategoriesFailure', {
          loadTime: elapsed
        });

        throw error;
      }
    }
  });

  return { categoryQuery };
};
