import { useAuthInfo } from '@propelauth/react';

export const useIsInternal = () => {
  const { user, impersonatorUserId } = useAuthInfo();

  const userDomain = user?.email.split('@')[1];
  const isImpersonating = impersonatorUserId !== undefined;
  const isInternalUser = userDomain === 'governgpt.com' || isImpersonating;

  return { isInternalUser };
};
