import HighlightCopy from '@/components/generic/highlight-copy';
import React from 'react';

export const CopyCitation: React.ComponentType<{
  friendlyName: string;
  approvedDate: string;
}> = ({ friendlyName, approvedDate }) => {
  return (
    <HighlightCopy hiddenChildren copyTitle='Copy Citation'>
      <p>Taken from: {friendlyName}</p>
      <p>Approved on: {approvedDate}</p>
    </HighlightCopy>
  );
};
