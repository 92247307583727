import { setAuthToken } from '@/register-worker';
import { useAuthInfo } from '@propelauth/react';
import React from 'react';
import { useAuthState } from './use-auth.state';

export const SyncAuth: React.ComponentType = () => {
  const { accessToken } = useAuthInfo();

  const { setAccessToken } = useAuthState();

  React.useEffect(() => {
    setAuthToken(accessToken);
    setAccessToken(accessToken);
  }, [accessToken, setAccessToken]);

  return <></>;
};
