import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';

import './markdown.css';

const createUrlTransform = (ddqId: string) => {
  return (url: string) => {
    const mediaPattern = /^media\/(.+\..+)$/;
    const match = url.match(mediaPattern);
    if (match) {
      return `${window.location.protocol}//${window.location.host}/ddq/${ddqId}/media/${match[1]}`;
    }
    return url;
  };
};



const BaseMarkdown: React.ComponentType<{
  className?: string;
  content: string;
  ddqId?: string;
}> = ({ className, content, ddqId }) => {
  let sanitizedContent = content;

  // Catch the case where markdown code block is created.
  if (content.startsWith('```markdown') && content.endsWith('```')) {
    sanitizedContent = sanitizedContent.slice(0, sanitizedContent.length - 3);
    sanitizedContent = sanitizedContent.slice(11);
  }

  let urlTransform = undefined;
  if (ddqId) {
    urlTransform = createUrlTransform(ddqId);
  }

  return (
    <ReactMarkdown
      className={`gg-markdown ${className}`}
      remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
      rehypePlugins={[
        rehypeRaw,
        [
          rehypeSanitize,
          {
            ...defaultSchema,
            attributes: { ...defaultSchema.attributes, span: ['className'] },
            tagNames: [...(defaultSchema.tagNames || []), 'mark']
          }
        ]
      ]}
      urlTransform={urlTransform}
    >
      {sanitizedContent}
    </ReactMarkdown>
  );
};

export const Markdown = React.memo(BaseMarkdown);
