import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';
import { captureEvent, runTimerInMs } from '@/lib/analytics';
import { DDQPairWithMeta } from '@/types';
import { authAtom } from '@/lib/use-auth.state';
import dayjs from 'dayjs';
import {
  questionObjAtom,
  currentEditStateCopyMutationAtom,
  getCurrentQuestionDataAtom
} from '../ddq/use-ddq-state';

type Config = {
  selectedResult: string | null;
  searchQueries: string[];
  currentSearchQueryIndex: number;
  searchQuery: string; // Added searchQuery string to the config object
};

const configAtom = atom<Config>({
  selectedResult: null,
  searchQueries: [],
  currentSearchQueryIndex: -1,
  searchQuery: '' // Initialize searchQuery
});

const addSearchQueryAtom = atom(null, (get, set, newQuery: string) => {
  const config = get(configAtom);
  const newSearchQueries = [...config.searchQueries, newQuery];
  set(configAtom, {
    ...config,
    searchQueries: newSearchQueries,
    currentSearchQueryIndex: newSearchQueries.length - 1
  });
});

const setSelectedResultAtom = atom(
  null,
  (get, set, selectedResult: string | null) => {
    const config = get(configAtom);

    set(configAtom, {
      ...config,
      selectedResult
    });
  }
);

const setCurrentSearchQueryIndexAtom = atom(
  null,
  (get, set, newIndex: number) => {
    const config = get(configAtom);
    set(configAtom, {
      ...config,
      currentSearchQueryIndex: newIndex
    });
  }
);

const currentSearchQueryIndexAtom = atom(
  (get) => get(configAtom).currentSearchQueryIndex
);

const searchQueryAtom = atom(
  (get) => get(configAtom).searchQuery,
  (get, set, newSearchQuery: string) => {
    const config = get(configAtom);
    set(configAtom, {
      ...config,
      searchQuery: newSearchQuery
    });
  }
);

const searchResultsQueryAtom = atomWithQuery((get) => {
  const config = get(configAtom);
  const searchQuery =
    config.searchQueries[config.currentSearchQueryIndex] || '';
  const accessToken = get(authAtom);

  return {
    queryKey: ['search_results', searchQuery],
    queryFn: async () => {
      if (!searchQuery) return [];

      const timer = runTimerInMs();
      try {
        captureEvent('loadingSearch', { query: searchQuery });

        const body = { search_query: searchQuery };

        const response = await fetch(
          `${import.meta.env.VITE_API_HOST}/search`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          }
        );

        if (!response.ok) {
          throw new Error('Failed to search responses');
        }

        const results = await response.json();
        const elapsed = timer();

        captureEvent('loadingSearchSuccess', {
          query: searchQuery,
          loadTime: elapsed
        });

        // Sort the results just like in search.tsx
        const sortedResults = results.sort(
          (a: DDQPairWithMeta, b: DDQPairWithMeta) => {
            const order = ['high', 'low', false];

            if (a.is_relevant === b.is_relevant)
              return (
                dayjs(b.pair.ddq.approved_date).unix() -
                dayjs(a.pair.ddq.approved_date).unix()
              );

            return order.indexOf(a.is_relevant) - order.indexOf(b.is_relevant);
          }
        );

        return sortedResults;
      } catch (error) {
        const elapsed = timer();

        captureEvent('loadingSearchFailure', {
          query: searchQuery,
          error: String(error),
          loadTime: elapsed
        });

        console.error(error);
        return [];
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    // Preserve results for an hour so they're not refetched as soon as the query is fetched again
    gcTime: 60 * 60 * 1000,
    staleTime: 60 * 60 * 1000
  };
});

const resultsAtom = atom<DDQPairWithMeta[]>((get) => {
  const data = get(searchResultsQueryAtom).data;
  return Array.isArray(data) ? data : [];
});

const isLoadingAtom = atom((get) => get(searchResultsQueryAtom).isLoading);

const selectedResultAtom = atom((get) => {
  const { selectedResult } = get(configAtom);
  const results = get(resultsAtom);

  if (!results) return null;

  const foundResult = results.find(
    (result) => result.pair.id === selectedResult
  );

  if (foundResult) return foundResult;

  return results.length > 0 ? results[0] : null;
});

const isSelectedResultInSavesFromSearchAtom = atom((get) => {
  const selectedResult = get(selectedResultAtom);
  const currentQuestionData = get(getCurrentQuestionDataAtom);

  if (!selectedResult || !currentQuestionData) return false;

  return currentQuestionData.savesFromSearch.some(
    (pair) => pair.pair.id === selectedResult.pair.id
  );
});

const paginationAtom = atom((get) => {
  const results = get(resultsAtom);
  const selectedResult = get(selectedResultAtom);

  const { current, next, prev, length } = (() => {
    if (selectedResult === null) {
      return {
        current: 0,
        next: results.length > 1 ? results[1].pair.id : undefined,
        prev: undefined,
        length: results.length
      };
    }

    const current = results.indexOf(selectedResult);
    const length = results.length;
    const prev = current > 0 ? results[current - 1].pair.id : undefined;
    const next =
      current < length - 1 ? results[current + 1].pair.id : undefined;

    return {
      current,
      next,
      prev,
      length
    };
  })();

  return {
    current,
    next,
    prev,
    length
  };
});

const searchQueriesAtom = atom((get) => get(configAtom).searchQueries);

const isCurrentAnswerCopyingAtom = atom((get) => {
  const questionObj = get(questionObjAtom);
  const result = get(selectedResultAtom);
  const mutateCurrentEditStateWithCopy = get(currentEditStateCopyMutationAtom);

  return (
    mutateCurrentEditStateWithCopy.isPending &&
    mutateCurrentEditStateWithCopy.variables.id === questionObj?.id &&
    mutateCurrentEditStateWithCopy.variables.copyPairId === result?.pair.id
  );
});

export function useSearchState() {
  const addSearchQuery = useSetAtom(addSearchQueryAtom);
  const setSelectedResult = useSetAtom(setSelectedResultAtom);
  const setCurrentSearchQueryIndex = useSetAtom(setCurrentSearchQueryIndexAtom);
  const currentSearchQueryIndex = useAtomValue(currentSearchQueryIndexAtom);
  const pagination = useAtomValue(paginationAtom);
  const results = useAtomValue(resultsAtom);
  const selectedResult = useAtomValue(selectedResultAtom);
  const [searchQuery, setSearchQuery] = useAtom(searchQueryAtom); // Export both getter and setter for searchQueryAtom
  const searchQueries = useAtomValue(searchQueriesAtom); // Export searchQueries getter
  const isLoading = useAtomValue(isLoadingAtom);
  const isCurrentAnswerCopying = useAtomValue(isCurrentAnswerCopyingAtom);
  const isSelectedResultInSavesFromSearch = useAtomValue(isSelectedResultInSavesFromSearchAtom);

  return {
    addSearchQuery,
    setSelectedResult,
    setCurrentSearchQueryIndex,
    currentSearchQueryIndex,
    pagination,
    results,
    selectedResult,
    searchQuery, // Include searchQuery in the return object
    setSearchQuery, // Include refetchSearchQuery in the return object
    searchQueries, // Include searchQueries in the return object
    isLoading, // Include isLoading in the return object
    isCurrentAnswerCopying,
    isSelectedResultInSavesFromSearch // Include isSelectedResultInSavesFromSearch in the return object
  };
}
