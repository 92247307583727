import { atom, useAtom } from 'jotai';

export const authAtom = atom<string | null | undefined>(undefined);

export const useAuthState = () => {
  const [accessToken, setAccessToken] = useAtom(authAtom);

  return {
    accessToken,
    setAccessToken
  };
};
