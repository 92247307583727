import logo from '../assets/black-logo-no-bg.svg';

export const Logo: React.FC = () => {
  return (
    <img
      src={logo}
      alt='Logo'
      className='h-12 cursor-pointer'
    />
  );
};
