'use client';

import { TooltipProvider } from '@/components/ui/tooltip';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup
} from '@/components/ui/resizable';

import cookie from 'js-cookie';
import { SearchList } from './search-results-list';
import { SearchDisplay } from './search-display';
import { Separator } from '@/components/ui/separator';
import { SearchesList } from './searches-list';

interface SearchRenderProps {
  defaultLayout: number[] | undefined;
  inDDQContext?: boolean;
}

export function SearchRender({
  defaultLayout = [15, 20, 65],
  inDDQContext = false
}: SearchRenderProps) {
  return (
    <TooltipProvider delayDuration={0}>
      <ResizablePanelGroup
        direction='horizontal'
        onLayout={(sizes: number[]) => {
          cookie.set(
            'search-render-resizable-panels:layout',
            JSON.stringify(sizes)
          );
        }}
        className='max-h-full items-stretch'
      >
        <ResizablePanel
          className='h-full'
          defaultSize={defaultLayout[0]}
          minSize={5}
        >
          <div className='h-full flex flex-col'>
            <div className='flex items-center px-4 py-2'>
              <div className='flex items-center gap-2 py-1 justify-between w-full'>
                <h1 className='text-xl font-bold'>Past Searches</h1>
                <div className='flex shrink-0 gap-2'>
                  {/** Place to drop buttons or whatever */}
                </div>
              </div>
            </div>
            <Separator />
            <div className='max-h-full flex flex-col flex-grow overflow-y-hidden mt-0'>
              <SearchesList />
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel
          className='h-full'
          defaultSize={defaultLayout[1]}
          minSize={15}
        >
          <div className='h-full flex flex-col'>
            <div className='flex items-center px-4 py-2'>
              <div className='flex items-center gap-2 py-1 justify-between w-full'>
                <h1 className='text-xl font-bold'>Search Results</h1>
                <div className='flex shrink-0 gap-2'>
                  {/** Place to drop buttons or whatever */}
                </div>
              </div>
            </div>
            <Separator />
            <div className='max-h-full flex flex-col flex-grow overflow-y-hidden mt-0'>
              <SearchList />
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={defaultLayout[2]} minSize={50}>
          <SearchDisplay inDDQContext={inDDQContext} />
        </ResizablePanel>
      </ResizablePanelGroup>
    </TooltipProvider>
  );
}
