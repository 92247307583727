import { TimedProgress } from '../ui/progress';

export function Loading() {
  return (
    <div key='1' className='flex flex-col min-h-screen'>
      <main className='flex-1 overflow-y-auto p-4 min-h-100 flex flex-col items-center justify-center'>
        <TimedProgress durationMs={200} progressStep={10} />
      </main>
    </div>
  );
}
