import { useState } from 'react';
import { useAuthInfo } from '@propelauth/react';
import { AnalysisResponse } from '@/types';
import { ResultType } from '@/components/ddq/auto-complete';
import { Button, ButtonLoading } from '@/components/ui/button'; // Assuming you have a Button component
import { useIsInternal } from '@/lib/is-internal';

export const useExportMixin = (
  analysisResponse?: AnalysisResponse,
  autoGenerationResults?: ResultType
) => {
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useAuthInfo();

  const { isInternalUser } = useIsInternal();
  
  const isExportEnabled = isInternalUser;

  const handleExportClick = async () => {
    if (!analysisResponse || !autoGenerationResults) return;

    setIsLoading(true);
    const ddqId = analysisResponse.id;

    if (ddqId) {
      try {
        const response = await fetch(`${import.meta.env.VITE_API_HOST}/${ddqId}/export`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to export data');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${analysisResponse.upload_name.split('.').slice(0, -1).join('.')}.docx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        console.error('Error exporting data:', error);
      }
    }
    setIsLoading(false);
  };

  const ExportButton = () => (
    isLoading ? <ButtonLoading>Export</ButtonLoading> : <Button onClick={handleExportClick} disabled={isLoading || !isExportEnabled}>Export</Button>
  );

  return { handleExportClick, ExportButton, isExportEnabled };
};
