'use client';
import { QuestionDisplay } from '@/components/ddq/components/question-display';
import { useDDQState } from '@/components/ddq/use-ddq-state';
import { Separator } from '@/components/ui/separator';
import { TooltipProvider } from '@/components/ui/tooltip';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup
} from '@/components/ui/resizable';

import { QuestionList } from './question-list';

import cookie from 'js-cookie';

import { Button, ButtonLoading } from '@/components/ui/button';
import { useAutoComplete } from '@/components/ddq/auto-complete';
import { useBlankStatus } from '@/lib/is-blank';
import { useExportMixin } from '@/components/ddq/export';
import { captureEvent } from '@/lib/analytics';
import { useIsInternal } from '@/lib/is-internal';
import { GenerationView } from './generation';
import { AnalysisResponse } from '@/types';
import { useEffect } from 'react';

interface DDQRenderProps {
  analysisResponse: AnalysisResponse
}

export function DDQRender(props: DDQRenderProps) {
  const { state, pairObj, setDDQAnalysis } = useDDQState();

  const [ddqState] = state;

  useEffect(() => {
    setDDQAnalysis(props.analysisResponse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.analysisResponse])

  const { analysisResponse: analysis } = ddqState;

  const autoComplete = useAutoComplete();

  const { isExportEnabled, ExportButton } = useExportMixin(
    analysis || undefined,
    autoComplete.results || undefined
  );

  const { isBlank } = useBlankStatus();

  const { isInternalUser } = useIsInternal();

  if (!analysis) return <></>;

  const layoutCookie = cookie.get('ddq-render-resizable-panels:layout');
  const defaultLayout = layoutCookie ? JSON.parse(layoutCookie) : [25, 50, 25];

  const allItems = analysis.similar_pairs;

  return (
    <TooltipProvider delayDuration={0}>
      <ResizablePanelGroup
        direction='horizontal'
        onLayout={(sizes: number[]) => {
          cookie.set(
            'ddq-render-resizable-panels:layout',
            JSON.stringify(sizes)
          );
        }}
        className='max-h-full items-stretch'
      >
        <ResizablePanel
          className='h-full'
          defaultSize={defaultLayout[0]}
          minSize={15}
        >
          <div className='h-full flex flex-col'>
            <div className='flex items-center px-4 py-2'>
              <div className='flex items-center gap-2 justify-between w-full'>
                <h1 className='text-xl font-bold'>Questionnaire</h1>
                <div className='flex shrink-0 gap-2'>
                  {isInternalUser && !autoComplete.isProcessing && (
                    <Button
                      onClick={() => {
                        captureEvent('autoDraftClick', {
                          id: ddqState.analysisResponse?.id || '',
                          friendly_name:
                            ddqState.analysisResponse?.friendly_name,
                          approved_date:
                            ddqState.analysisResponse?.approved_date,
                          upload_name:
                            ddqState.analysisResponse?.upload_name || '',
                          upload_file_type:
                            ddqState.analysisResponse?.upload_mime_type
                        });
                        autoComplete.process({ ddqPairs: allItems });
                      }}
                      disabled={isBlank}
                    >
                      Auto-Draft
                    </Button>
                  )}
                  {isInternalUser && autoComplete.isProcessing && (
                    <ButtonLoading>Auto-Draft</ButtonLoading>
                  )}
                  {isExportEnabled && !isBlank && <ExportButton />}
                </div>
              </div>
            </div>
            <Separator />
            <div className='max-h-full flex flex-col flex-grow overflow-y-hidden mt-0'>
              <QuestionList
                items={allItems}
                autoResults={autoComplete.results}
              />
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
          <QuestionDisplay key={pairObj && pairObj[0].id} />
        </ResizablePanel>
        <ResizableHandle withHandle />
        {!isBlank && (
          <ResizablePanel defaultSize={defaultLayout[2]} minSize={15}>
            {pairObj && (
              <GenerationView
                key={pairObj[0].id}
                selectedPair={pairObj}
                autoResult={autoComplete.results[pairObj[0].id]}
              />
            )}
          </ResizablePanel>
        )}
      </ResizablePanelGroup>
    </TooltipProvider>
  );
}
