import { forwardRef } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useSearchState } from '../use-search-state';

interface SearchInputProps {
  onSearch: (query: string) => void;
  onClickInput?: () => void;
  placeholder?: string;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(({ onSearch, onClickInput, placeholder = 'Search DDQs...' }, ref) => {
  const { searchQuery, setSearchQuery, isLoading } = useSearchState();

  const handleSearch = () => {
    if (typeof searchQuery === 'string' && searchQuery.length > 0) {
      onSearch(searchQuery);
    }
  };

  return (
    <Input
      ref={ref}
      className={'mr-2'}
      type='text'
      placeholder={placeholder}
      value={searchQuery}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.currentTarget.value);
      }}
      onClick={() => {
        if (onClickInput) {
          onClickInput();
        }
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
      disabled={isLoading}
    />
  );
});

interface SearchButtonProps {
  onSearch: (query: string) => void;
  onClickButton?: () => void;
}

const SearchButton: React.FC<SearchButtonProps> = ({ onSearch, onClickButton }) => {
  const { searchQuery, isLoading } = useSearchState();

  const handleSearch = () => {
    if (typeof searchQuery === 'string' && searchQuery.length > 0) {
      onSearch(searchQuery);
    }
  };

  return (
    <Button
      onClick={() => {
        if (onClickButton) {
          onClickButton();
        } else {
          handleSearch();
        }
      }}
      disabled={isLoading}
    >
      Search
    </Button>
  );
};

export { SearchInput, SearchButton };
