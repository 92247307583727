import React from 'react';
import { useCategoryQuery } from '@/queries/category';
import { Badge } from '../ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '../ui/tooltip';
import { TooltipArrow } from '@radix-ui/react-tooltip';

interface CategoryTagProps {
  categoryId: string | null;
  contextCategoryId?: string;
}

const CategoryTag: React.FC<CategoryTagProps> = ({
  categoryId,
  contextCategoryId
}) => {
  const { categoryQuery } = useCategoryQuery();
  const category = categoryQuery.data?.find((cat) => cat.id === categoryId);
  // const contextCategory = contextCategoryId ? categoryQuery.data?.find(cat => cat.id === contextCategoryId) : null;

  const isContextMatch = contextCategoryId && contextCategoryId === categoryId;

  let show = false;
  let tagStyle = 'bg-gray-500';
  let tooltip = '';

  if (isContextMatch) {
    show = true;
    tagStyle = 'bg-green-500';
    tooltip = 'When the category of this response matches matches that of the draft you are researching then this badge is green.';
  } else if (contextCategoryId && !isContextMatch) {
    show = true;
    tagStyle = 'bg-yellow-500';
    tooltip = 'When the category of this response is different from the draft you are researching then this badge is yellow.';
  } else if (!category) {
    show = true;
    tagStyle = 'bg-gray-500';
    tooltip = 'This response comes from a document without a category.';
  } else if (!contextCategoryId) {
    show = true;
    tagStyle = 'bg-gray-500';
    tooltip = "When the DDQ you're comparing against doesn't have a category this badge stays gray because this response's category isn't compared against anything."
  }

  if (!show) return null;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <Badge className={`${tagStyle}`}>{category?.name || 'Uncategorized'}</Badge>
        </TooltipTrigger>
        <TooltipContent side='right'>
          <TooltipArrow />
          <p>
            {tooltip}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default CategoryTag;
