import { cn } from '@/lib/utils';
import { ScrollArea } from '@/components/ui/scroll-area';

import '@/css/list.css';
import { useSearchState } from '../use-search-state';

export function SearchesList() {
  const { searchQueries, currentSearchQueryIndex, setCurrentSearchQueryIndex } = useSearchState();

  return (
    <ScrollArea className='flex-grow pt-4 scroll-area-no-table'>
      <div className='flex flex-col-reverse gap-2 p-4 pt-0'>
        {searchQueries.map((query, i) => (
          <button
            title={query}
            key={i}
            className={cn(
              'flex flex-col items-start gap-2 rounded-lg border p-2 text-left text-sm transition-all hover:bg-blue-300',
              currentSearchQueryIndex === i && 'bg-blue-200 font-bold'
            )}
            onClick={() => {
              /* captureEvent('analysisClickSearchQuery', {
                query,
                index: i
              }); */

              setCurrentSearchQueryIndex(i);
            }}
          >
            <div className='flex max-w-full flex-col gap-1'>
              <div className='font-normal max-h-10 overflow-hidden text-ellipsis whitespace-nowrap' title={query}>
                {query}
              </div>
            </div>
          </button>
        ))}
      </div>
    </ScrollArea>
  );
}
