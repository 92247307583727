import { cn } from '@/lib/utils';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useDDQState } from '../use-ddq-state';
import { AnalysisResponse } from '@/types';

import { Markdown } from '@/components/generic/markdown';

import '@/css/list.css';
import { captureEvent } from '@/lib/analytics';
import { ResultType } from '@/components/ddq/auto-complete';
import { Badge } from '@/components/ui/badge';
import { CheckIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { Loader2 } from 'lucide-react';

import { useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

interface QuestionListProps {
  items: AnalysisResponse['similar_pairs'];
  autoResults?: ResultType;
}

export function QuestionList({ items, autoResults }: QuestionListProps) {
  const { state: [ddqState], setSelectedQuestion } = useDDQState()
  const [userClicks, setUserClicks] = useState<string[]>([]);

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (userClicks[userClicks.length - 1] === ddqState.selectedQuestion) return;

    if (scrollAreaRef.current) {
      const selectedElement = scrollAreaRef.current.querySelector(
        `[data-id="${ddqState.selectedQuestion}"]`
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [ddqState.selectedQuestion, userClicks]);

  return (
    <ScrollArea
      ref={scrollAreaRef}
      className='flex-grow pt-4 scroll-area-no-table'
    >
      <div className='flex flex-col gap-2 p-4 pt-0'>
        {items.map(([pair, similar_pairs], i) => (
          <button
            data-id={pair.id}
            key={pair.id}
            className={cn(
              'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent',
              ddqState.selectedQuestion === pair.id && 'bg-muted border-black'
            )}
            onClick={() => {
              captureEvent('analysisClickQuestionCard', {
                doc_id: pair.ddq_id,
                pair_id: pair.id,
                index: i
              });

              setUserClicks([...userClicks, pair.id]);

              setSelectedQuestion(pair.id)
            }}
          >
            <div className='flex w-full gap-1'>
              <div>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <Badge variant='outline'>{i + 1}</Badge>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side='right'>
                      <p>The question number in this document.</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              {autoResults && Object.keys(autoResults || {}).length > 0 && (
                <div className='flex items-center gap-2'>
                  {autoResults[pair.id]?.processing && (
                    <Badge variant='outline'>
                      <Loader2 className={'h-4 w-4 animate-spin'}></Loader2>
                      &nbsp; Thinking...
                    </Badge>
                  )}
                  {autoResults[pair.id]?.complete &&
                    (autoResults[pair.id]?.response?.generation ? (
                      <Badge variant='default' className={'text-green-500'}>
                        <CheckIcon />
                        &nbsp; Found Answer
                      </Badge>
                    ) : (
                      <Badge variant='default' className={'text-yellow-500'}>
                        <ExclamationTriangleIcon />
                        &nbsp; Requires Response
                      </Badge>
                    ))}
                </div>
              )}
              {Object.keys(autoResults || {}).length === 0 && (
                <div className='flex items-center gap-2'>
                  {similar_pairs.length === 0 && (
                    <Badge variant='default' className='text-yellow-500'>
                      <ExclamationTriangleIcon />
                      &nbsp; No responses found
                    </Badge>
                  )}
                  {similar_pairs.length === 1 && (
                    <Badge variant='default' className='text-green-500'>
                      <CheckIcon />
                      &nbsp;1 response found
                    </Badge>
                  )}
                  {similar_pairs.length > 1 && (
                    <Badge variant='default' className='text-green-500'>
                      <CheckIcon />
                      &nbsp;{similar_pairs.length} responses found
                    </Badge>
                  )}
                </div>
              )}
            </div>
            <div className='font-semibold flex-grow' title={pair.question}>
              <Markdown
                className='font-normal max-h-10 overflow-y-hidden overflow-x-hidden'
                content={pair.question}
                ddqId={pair.ddq_id}
              />
            </div>
            <div className='line-clamp-2 text-xs text-muted-foreground'>
              <Markdown
                className='font-normal max-h-16 overflow-hidden pl-2 border-l-2'
                content={pair.answer}
                ddqId={pair.ddq_id}
              />
            </div>
          </button>
        ))}
      </div>
    </ScrollArea>
  );
}
