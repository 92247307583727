import { Workbox } from 'workbox-window';

import workerUrl from '../sw.js?worker&url';

console.log('workerURL', workerUrl);

const devParam = import.meta.env.DEV ? '&dev' : '';

let wb: Workbox | undefined = undefined;

if ('serviceWorker' in navigator) {
  wb = new Workbox(workerUrl.replace('type=module&', '') + devParam);

  (async () => {
    await wb.register();

    wb.messageSkipWaiting();

    await Promise.race([wb.active, wb.controlling]);

    console.log('asking version');

    const response = await wb.messageSW({ type: 'GET_VERSION' });

    console.log('sw version response', response);

    setInterval(async () => {
      if (!wb) return;

      const response = await wb.messageSW({ type: 'KEEP_ALIVE' });

      console.log('sw keep alive response', response);
    }, 2000);
  })();
}

export const setAuthToken = async (token?: string | null) => {
  if (!wb) return;

  const response = await wb.messageSW({
    type: 'TOKEN',
    token: token
  });

  console.log('sw token response', response);
};
