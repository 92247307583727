import { useAuthInfo } from '@propelauth/react';

export const useUserData = () => {
  const { user, orgHelper } = useAuthInfo();

  const org = orgHelper?.getOrgs()[0];

  const propelOrganizationUrl = `https://app.propelauth.com/proj/e716513f-b74a-4289-a7de-8a7dbbccd9fe/management/env/prod/org/${org?.orgId}?tab=members`;
  const organizationName = org?.orgName;
  const propelUserUrl = `https://app.propelauth.com/proj/e716513f-b74a-4289-a7de-8a7dbbccd9fe/management/env/prod/users/${user?.userId}?tab=settings`;

  const inEventFormat = {
    propel_organization_url: propelOrganizationUrl,
    propel_user_url: propelUserUrl,
    organization_name: organizationName
  }

  return {
    propelOrganizationUrl,
    organizationName,
    propelUserUrl,
    inEventFormat
  }
};
